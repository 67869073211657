<template>
  <div class="mt-5">
    <v-list-item>
      <v-autocomplete
        outlined
        dense
        :loading="principalLoading"
        @keyup="searchPrincipalAction"
        :search-input.sync="searchPrincipal"
        :items="principals"
        hide-no-data
        class="pa-0"
        label="Principle's signature"
        v-model="principal"
        style="width: 100%"
      />
    </v-list-item>
    <v-list-item>
      <v-autocomplete
        outlined
        dense
        :search-input.sync="searchExamCoordinator"
        :items="coordinators"
        @keyup="searchExamCoordinatorAction"
        hide-no-data
        label="Exam coordinator's Signature(GLOBAL)"
        :loading="coordinatorLoading"
        class="pa-0"
        v-model="exam_coordinator"
        style="width: 100%"
      />
    </v-list-item>
    <v-list-item>
      <v-autocomplete
        outlined
        dense
        :search-input.sync="searchSuperitendent"
        :items="superintendents"
        @keyup="searchSuperitendentAction"
        hide-no-data
        :loading="superitendentLoading"
        class="pa-0"
        label="Superintendent's Signature"
        v-model="superintendent"
        style="width: 100%"
      />

      <v-icon
        v-if="superintendent"
        style="color: red; cursor: pointer; vertical-align: super"
        @click="SRemovedialog = true"
        >close</v-icon
      >
    </v-list-item>

    <v-list-item>
      <v-autocomplete
        :search-input.sync="searchAccountant"
        :items="accountants"
        outlined
        dense
        @keyup="searchAccountantAction"
        hide-no-data
        label="Accountant's Signature"
        :loading="accountantLoading"
        class="pa-0"
        v-model="accountant"
        style="width: 100%"
      />

      <v-icon
        v-if="accountant"
        style="color: red; cursor: pointer; vertical-align: super"
        @click="ARemovedialog = true"
        >close</v-icon
      >
    </v-list-item>
    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="blue"
        v-model="allow_back_date_attendance"
      >
        <template v-slot:label>
          <div style="color: #333">
            Allow Back Date Attendance
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Class teacher, administrators will be able to take admission for
                previous dates.
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>
    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="blue"
        v-model="enable_qr_log_sms"
      >
        <template v-slot:label>
          <div style="color: #333">
            Enable Sms For Qr Log
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Enable if you want to receive sms for Qr Log
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>
    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="blue"
        v-model="enable_attendance_by_qr"
      >
        <template v-slot:label>
          <div style="color: #333">
            Enable Attendance by Qr
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Enable if you want to make attendace by scanning qr code
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>
    <v-list-item></v-list-item>
    <v-list-item style="margin-top: 34px;">
      <v-btn block large color="info outlined" @click="academicSubmit"
        >Save</v-btn
      >
    </v-list-item>
    <v-dialog v-model="SRemovedialog" persistent max-width="290">
      <v-card>
        <v-card-title class="title pa-3 red white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Please, wait!
        </v-card-title>
        <v-card-text class="ma-0"
          >Are you sure you want to remove Superitendent Signature?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" outlined small @click="SRemovedialog = false"
            >No
          </v-btn>
          <v-btn color="error" outlined small @click="removeSuperitendent"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ARemovedialog" persistent max-width="290">
      <v-card>
        <v-card-title class="title pa-3 red white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Please, wait!
        </v-card-title>
        <v-card-text class="ma-0"
          >Are you sure you want to remove Account Signature?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" outlined small @click="ARemovedialog = false"
            >No
          </v-btn>
          <v-btn color="error" outlined small @click="removeAccountant"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  // props: {
  //   batch: {
  //     required: true,
  //   },
  // },
  computed: {
    ...mapState(["batch"]),
  },
  data: () => ({
    searchPrincipal: null,
    principal: "",
    principalLoading: false,
    principals: [],

    superitendentLoading: false,
    superintendents: [],
    searchSuperitendent: null,
    superintendent: "",

    accountantLoading: false,
    accountants: [],
    searchAccountant: null,
    accountant: "",

    coordinatorLoading: false,
    coordinators: [],
    searchExamCoordinator: null,
    exam_coordinator: "",

    SRemovedialog: false,
    ARemovedialog: false,
    allow_back_date_attendance: false,
    enable_qr_log_sms: false,
    enable_attendance_by_qr: false,
    is_hs_status: "",
  }),
  mounted() {
    (this.is_hs_status = JSON.parse(this.$storage.get("batch"))), "....";
    this.getAcademicSetting();
  },
  methods: {
    getAcademicSetting() {
      this.principalLoading = true;
      this.coordinatorLoading = true;
      this.superitendentLoading = true;
      this.accountantLoading = true;
      this.$rest
        .get("/api/academic-setting?is_hs=" + this.is_hs_status.is_hs)
        .then(({ data }) => {
          this.allow_attendance_rollback =
            data.academic.academic.allowAttendanceRollback || false;

          this.allow_back_date_attendance =
            data.academic.academic.allowBackdateAttendance || false;

          this.enable_qr_log_sms =
            data.academic.academic.enableQrLogSms || false;
            
          this.enable_attendance_by_qr =
            data.academic.academic.enableAttendanceByQr || false;
          if (
            (data,
            data.signature.principal && data.signature.principal.length > 0)
          ) {
            if (this.is_hs_status.is_hs) {
              this.principals = [
                {
                  value: data.academic.academic.college_signature.principal,
                  text: data.signature.principal,
                },
              ];
              this.principal =
                data.academic.academic.college_signature.principal;
            } else {
              this.principals = [
                {
                  value: data.academic.academic.signature.principal,
                  text: data.signature.principal,
                },
              ];
              this.principal = data.academic.academic.signature.principal;
            }
          }
          if (
            data.signature.exam_coordinator &&
            data.signature.exam_coordinator.length > 0
          ) {
            if (this.is_hs_status.is_hs) {
              this.coordinators = [
                {
                  value:
                    data.academic.academic.college_signature.exam_coordinator,
                  text: data.signature.exam_coordinator,
                },
              ];
              this.exam_coordinator =
                data.academic.academic.college_signature.exam_coordinator;
            } else {
              this.coordinators = [
                {
                  value: data.academic.academic.signature.exam_coordinator,
                  text: data.signature.exam_coordinator,
                },
              ];
              this.exam_coordinator =
                data.academic.academic.signature.exam_coordinator;
            }
          }

          if (
            data.signature.superintendent &&
            data.signature.superintendent.length > 0
          ) {
            if (this.is_hs_status.is_hs) {
              this.superintendents = [
                {
                  value:
                    data.academic.academic.college_signature.superintendent,
                  text: data.signature.superintendent,
                },
              ];
              this.superintendent =
                data.academic.academic.college_signature.superintendent;
            } else {
              this.superintendents = [
                {
                  value: data.academic.academic.signature.superintendent,
                  text: data.signature.superintendent,
                },
              ];
              this.superintendent =
                data.academic.academic.signature.superintendent;
            }
          }
          if (
            data.signature.accountant &&
            data.signature.accountant.length > 0
          ) {
            if (this.is_hs_status.is_hs) {
              this.accountants = [
                {
                  value: data.academic.academic.college_signature.accountant,
                  text: data.signature.accountant,
                },
              ];
              this.accountant =
                data.academic.academic.college_signature.accountant;
            } else {
              this.accountants = [
                {
                  value: data.academic.academic.signature.accountant,
                  text: data.signature.accountant,
                },
              ];
              this.accountant = data.academic.academic.signature.accountant;
            }
          }
        })
        .finally(() => {
          this.principalLoading = false;
          this.coordinatorLoading = false;
          this.superitendentLoading = false;
          this.accountantLoading = false;
        });
    },

    academicSubmit() {
      this.$rest
        .put("/api/setting", {
        //  allow_attendance_rollback: this.allow_attendance_rollback.toString(),
          allow_back_date_attendance:
            this.allow_back_date_attendance.toString(),
          enable_qr_log_sms:
            this.enable_qr_log_sms.toString(),
          enable_attendance_by_qr:
            this.enable_attendance_by_qr.toString(),
          flag: "academic",
          is_hs: this.is_hs_status.is_hs,
          principal: parseInt(this.principal),
          exam_coordinator: this.exam_coordinator,
          superintendent: this.superintendent,
          accountant: this.accountant,

          principal_name: this.searchPrincipal,
          exam_coordinator_name: this.searchExamCoordinator,
          accountant_name:this.searchAccountant,
          superintendent_name:this.searchSuperitendent
        })
        .then(() => {
          this.$events.fire("notification", {
            message: "Setting updated successfully",
            status: "success",
          });
          this.getAcademicSetting();
        })
        .finally(() => {
          this.pricipalLoading = false;
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "success",
          });
        });
    },
    removeSuperitendent() {
      this.superintendent = "";
      this.academicSubmit();
      this.SRemovedialog = false;
    },
    removeAccountant() {
      this.accountant = "";
      this.academicSubmit();
      this.ARemovedialog = false;
    },

    searchPrincipalAction(val) {
      val = this.searchPrincipal;
      if (!val) return;
      this.pricipalLoading = true;
      this.coordinatorLoading = false;
      this.accountantLoading = false;
      this.superitendentLoading = false;
      this.$rest
        .get("api/setting-teacher-list?name=" + val + "&role=principal")
        .then(({ data }) => {
          this.principals = data.map((item) => {
            return { value: item.id.toString(), text: item.name };
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.studentLoading = false;
          this.pricipalLoading = false;
        });
    },

    searchExamCoordinatorAction(val) {
      val = this.searchExamCoordinator;
      if (!val) return;
      if (this.isLoading) return;
      this.pricipalLoading = false;
      this.coordinatorLoading = true;
      this.superitendentLoading = false;
      this.$rest
        .get("api/setting-teacher-list?name=" + val + "&role=exam-coordinator")
        .then(({ data }) => {
          this.coordinators = data.map((item) => {
            return { value: item.id.toString(), text: item.name };
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.studentLoading = false;
          this.coordinatorLoading = false;
        });
    },
    searchSuperitendentAction(val) {
      val = this.searchSuperitendent;
      if (!val) return;
      if (this.isLoading) return;

      this.pricipalLoading = false;
      this.coordinatorLoading = false;
      this.accountantLoading = false;
      this.superitendentLoading = true;
      this.$rest
        .get("api/setting-teacher-list?name=" + val + "&role=teacher")
        .then(({ data }) => {
          this.superintendents = data.map((item) => {
            return { value: item.id.toString(), text: item.name };
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.studentLoading = false;
          this.superitendentLoading = false;
        });
    },
    searchAccountantAction(val) {
      val = this.searchAccountant;
      if (!val) return;
      if (this.isLoading) return;

      this.pricipalLoading = false;
      this.coordinatorLoading = false;
      this.superitendentLoading = false;
      this.accountantLoading = true;
      this.$rest
        .get("api/setting-teacher-list?name=" + val + "&role=accountant")
        .then(({ data }) => {
          this.accountants = data.map((item) => {
            return { value: item.id.toString(), text: item.name };
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.studentLoading = false;
          this.accountantLoading = false;
        });
    },
  },
};
</script>
